import { useEffect, useCallback } from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";

const GetAuthToken = () => {
  const { instance, inProgress, accounts } = useMsal();
  const IsAuthenticated = useIsAuthenticated();

  const getToken = useCallback(() => {
    const accessTokenRequest = {
      scopes: [`api://${process.env.REACT_APP_CLIENT_ID as string}/access`],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => {
        // Acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        sessionStorage.setItem("authToken", accessToken);
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(function (accessTokenResponse) {
              // Acquire token interactive success
              const accessToken = accessTokenResponse.accessToken;
              sessionStorage.setItem("authToken", accessToken);
            })
            .catch(function (error) {
              // Acquire token interactive failure
              console.log(error);
            });
        }
        console.log(error);
      });
  }, [accounts, instance]);

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      getToken();
    }
    if (!IsAuthenticated) {
      getToken();
    }
  }, [getToken, inProgress, IsAuthenticated]);

  return null;
};

export default GetAuthToken;
