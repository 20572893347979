import { AuthProvider } from "react-admin";
import { API_BASE_URL, IApimValues } from "Utils/apiUtils";

const GetAuthProvider = (): AuthProvider => {
  return {
    login: (params) => Promise.resolve(),
    checkError: (error) => Promise.resolve(),
    checkAuth: (params) => Promise.resolve(),
    logout: () => Promise.resolve(),
    getPermissions: () => {
      const accessToken = sessionStorage.getItem(`authToken`);
      const request = new Request(
        `${API_BASE_URL(IApimValues.mainpolicyapi)}/Policies/admin-groups`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .then((permissions) => {
          return permissions[0];
        })
        .catch(() => {
          throw new Error("Network error");
        });
    },
  };
};

export default GetAuthProvider;
