import polyglotI18nProvider from "ra-i18n-polyglot";
import it from "ra-language-italian";

const translations = { it };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  "it", // default locale
  [{ locale: "it", name: "Italiano" }]
);
