export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority:
      "https://login.microsoftonline.com/fbe525ef-8b69-4795-8dd4-b7f759311fb6",
    redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
